import qs from 'qs'
import { formatOppFinderApiParams } from 'helpers/opportunity_finder'
import { KEYWORD_LISTS_ENDPOINT } from 'constants/api'
import {
  buildFullPath,
  buildFullPathClean,
  buildFilterQuery,
  buildFilter
} from './build_paths'

import { get, post, put, destroy } from './api/helpers'

/*
 *               _
 *              | |
 *           ___| |_ ___  _ __
 *          / __| __/ _ \| '_ \
 *          \__ \ || (_) | |_) |
 *          |___/\__\___/| .__/
 *                       | |
 *                       |_|
 *
 * Please do not add to this file.
 * Additions to this file will get pulled into the main JS bundle where they probably don't need to be
 * Please create a new file, add your methods/constants to that and import just the code you need.
 *
 * For more info see https://github.com/Junglescout/docs/blob/master/client/Code-Splitting.md
 */

import academy from './api/academy'
import accounts from './api/accounts'
import admin from './api/admin'
import adminUsers from './api/admin/users'
import alerts from './api/alerts'
import emailUnsubscribeLink from './api/email_unsubscribe_link'
import historicalData from './api/historical_data'
import marketInsights from './api/market_insights'
import ppc from './api/ppc'
import product from './api/product'
import productAlerts from './api/product_alerts'
import productCosts from './api/product_costs'
import salesAnalytics from './api/sales_analytics'
import seats from './api/seats'
import sessions from './api/sessions'
import settings from './api/settings'
import subscriptionItems from './api/subscription_items'
import users from './api/users'

const RESCRAPE_TIMEOUT = 300000 // 5 minutes - because each scrape can take longer than our existing timeouts (also in dev env)
const LONG_FETCH_TIMEOUT = 120000 // 2 minutes - to support China, where the firewall causes a different magnitude of delays
const KEYWORD_SCOUT_REQUEST_TIMEOUT = 60000 // 1 minute - to support filtering organic and sponsored data

function getProductsAsPost(path, data) {
  const params = {
    ...data.filters,
    excludeTopBrands: data.excludeTopBrands,
    collapseParent: data.collapseParent
  }

  return post(
    buildFullPath(path, {
      skipCounter: data.skipCounter
    }),
    {
      data: params,
      timeout: 45000, // increase timeout to 45 seconds
      featureLimitKey: 'productDatabase'
    }
  )
}

export default {
  fetchSellerPlans() {
    const path = 'api/available_seller_plans'
    return get(path)
  },

  getAdminBasePlans(params) {
    const path = buildFullPath(
      'api/admin/subscription_plans/base_plans',
      params
    )
    return get(path)
  },

  getAdminCampaigns(params) {
    const path = buildFullPath('api/admin/subscription_plans/campaigns', params)
    return get(path)
  },

  updateAdminSubscriptionPlan(basePlanId, data) {
    const path = `api/admin/subscription_plans/${basePlanId}`
    return put(path, { data })
  },

  removePaymentMethod(params) {
    const path = buildFullPath('api/admin/remove_payment_method', params)
    return get(path)
  },

  createAdminSubscriptionPlan(data) {
    const path = `api/admin/subscription_plans`
    return post(path, { data })
  },

  alipaySetupIntent(data) {
    const path = `api/accounts/alipay_setup_intent`
    return post(path, { data })
  },

  deleteAdminBasePlan(data) {
    const path = `api/admin/subscription_plans/${data.id}`
    return destroy(path, { data })
  },

  duplicateAdminSubscriptionPlan(planId, planType) {
    const path = `api/admin/subscription_plans/${planId}/duplicate?entity_type=${planType}`
    return post(path)
  },

  viewAdminSubscriptionPlan(planId, planType) {
    const path = `api/admin/subscription_plans/${planId}?entity_type=${planType}`
    return get(path)
  },

  deleteAdminSubscriptionPlan(planId, planType) {
    const path = `api/admin/subscription_plans/${planId}?entity_type=${planType}`
    return destroy(path)
  },

  adminAsinRescrape(data) {
    const path = buildFullPath('api/admin/products/rescrape', data)
    return post(path, { data, timeout: RESCRAPE_TIMEOUT })
  },

  adminGetUsers(data) {
    const path = buildFullPath('api/admin/users', data)
    return get(path)
  },

  adminGetJungleScoutUsers() {
    const path = 'api/admin/junglescout_users'
    return get(path)
  },

  adminGetJungleScoutUserVersions(data) {
    const path = buildFullPath('api/admin/junglescout_user/versions', data)
    return get(path)
  },

  adminGetAdminPermissionsLookup() {
    const path = 'api/admin/permissions_lookup'
    return get(path)
  },

  adminEmailUsersCsv(data) {
    const path = 'api/admin/email_users_csv'
    return post(path, { data })
  },

  adminGetUserPlans() {
    const path = 'api/admin/user_plan_dropdown'
    return get(path)
  },

  adminRolesCsv() {
    const path = 'api/admin/admin_roles_csv_export'
    return get(path)
  },

  adminGetUser(userId) {
    const path = `api/admin/user?user_id=${userId}`
    return get(path)
  },

  adminCreateUser(data) {
    const path = `api/admin/create_user`
    return post(path, { data })
  },

  adminUpdateUser(data) {
    const path = `api/admin/update_user`
    return put(path, { data })
  },

  stripeBalanceTransaction(data) {
    const path = `api/admin/user/stripe_balance_transaction`
    return put(path, { data })
  },

  adminResetUserSearchLimits(data) {
    const path = `api/admin/reset_search_limits`
    return put(path, { data })
  },

  adminUpdateUserMembership(data) {
    const path = `api/admin/update_user_membership`
    return put(path, { data })
  },

  adminCreateNote(data) {
    const path = 'api/admin/admin_notes'
    return post(path, { data })
  },

  adminUpdateNote(noteId, data) {
    const path = `api/admin/admin_notes/${noteId}`
    return put(path, { data })
  },

  adminRefundStripeCharge(data) {
    const path = 'api/admin/stripe_refund'
    return post(path, { data })
  },

  adminAddAdditionalUser(data) {
    const path = 'api/admin/add_additional_user'
    return post(path, { data })
  },

  adminRevokeAdditionalUser(data) {
    const path = 'api/admin/revoke_additional_user'
    return post(path, { data })
  },

  adminUpdateAccessRights(data) {
    const path = 'api/admin/update_access_rights'
    return put(path, { data })
  },

  adminRemoveAccessRights(data) {
    const path = 'api/admin/remove_access_rights'
    return destroy(path, { data })
  },

  adminRestoreProductTracks(data) {
    const path = 'api/admin/restore_product_tracks'
    return post(path, { data })
  },

  adminCancelMembership(data) {
    const path = 'api/admin/cancel_membership'
    return post(path, { data })
  },

  adminReactivateMembership(data) {
    const path = 'api/admin/reactivate_membership'
    return post(path, { data })
  },

  adminDeleteApiSubscription(data) {
    const path = 'api/admin/delete_api_subscription'
    return post(path, { data })
  },

  adminDeleteMembership(data) {
    const path = 'api/admin/delete_membership'
    return post(path, { data })
  },

  adminBanUser(data) {
    const path = 'api/admin/ban_user'
    return post(path, { data })
  },

  reset2FA(data) {
    const path = `api/admin/reset_2fa`
    return post(path, { data })
  },

  adminGetJunglescoutUsers(data) {
    const path = buildFullPath('api/admin/junglescout_users', data)
    return get(path)
  },

  fetchStripePlans() {
    const path = 'api/admin/stripe_plans'
    return get(path)
  },

  loadGlobalData() {
    const path = `api/global_data`
    return get(path)
  },

  loadAccountData() {
    const path = `api/account_data`
    return get(path)
  },

  stripeUpgradeLink(data) {
    const path = buildFullPathClean('api/edit_subscription', data)
    return get(path)
  },

  billingInfoLink() {
    const path = 'api/billing_info'
    return get(path)
  },

  editPlanQuantity() {
    const path = 'api/edit_plan_quantity'
    return get(path)
  },

  registerAuthy(data) {
    const path = `api/authy/register`
    return post(path, { data })
  },

  deleteAuthy() {
    const path = `api/authy/delete`
    return destroy(path)
  },

  verifyAuthyToken(data) {
    const path = `api/authy/verify`
    return post(path, { data })
  },

  getRegistrationInfo(data) {
    const path = `api/new_registration`
    return post(path, { data, timeout: LONG_FETCH_TIMEOUT }) // TODO: maintain separate timeouts per-locale, ie longer for China
  },

  checkRegistrationStatus(data) {
    const path = `api/check_registration_status`
    return post(path, { data })
  },

  captureEmail(data) {
    const path = `api/capture_email`
    return post(path, { data })
  },

  checkEligibility(key, token) {
    const path = `api/check_eligibility?${key}=${token}`
    return get(path)
  },

  registerUser(data) {
    const path = `api/create_registration`
    return post(path, { data, timeout: LONG_FETCH_TIMEOUT }) // TODO: maintain separate timeouts per-locale, ie longer for China
  },

  registerUserWithoutPayment(data) {
    const path = `api/no_payment_registration`
    return post(path, { data, timeout: LONG_FETCH_TIMEOUT })
  },

  existingUserCheckoutPurchase(data) {
    const path = `api/in_app_purchase`
    return post(path, { data })
  },

  registerAdditionalUser(data) {
    const path = `api/register_additional_user`
    return post(path, { data })
  },

  addAdditionalUser(data) {
    const path = `api/add_additional_user`
    return post(path, { data })
  },

  getUpgradePlans() {
    const path = `api/upgrade_plans`
    return get(path)
  },

  getRelatedKeywords(data, format = '') {
    const basePath = `api/keyword_engine/get_related_keywords${format}`
    const path = buildFullPath(basePath, data)

    return get(path, {
      timeout: KEYWORD_SCOUT_REQUEST_TIMEOUT,
      featureLimitKey: 'keywordScout'
    })
  },

  getRelatedKeywordsCsvJobStatus(jobId) {
    return get(
      `api/keyword_engine/related_keywords_csv_job_status?job_id=${jobId}`
    )
  },

  /**
   * Proposed future work:
   *
   * This API is only being used on Opportunity Finder page. With "PRODUCT_DATABASE_UPDATE_ENDPOINT" fflag enabled,
   * the only required params are {excludeTopBrands} and {country}. After this fflag will be enabled for all users,
   * we can try to clean up and refactor all the UI logic and only pass filters that have been changed.
   * All ES related logic should be moved to the API.
   *
   * Accepted filters on the API (under "PRODUCT_DATABASE_UPDATE_ENDPOINT" fflag):
      {
        country: 'us,  <= REQUIRED
        excludeTopBrands: true,  <= REQUIRED
        page: { size: 200, from: 0 },
        sort: { column: 'opportunityScore', direction: 'desc' },
        filters: {
          avgPrice: { min: 0, max: 1 },
          avgUnitsSold: { min: 0, max: 1 },
          categories: ['Baby'],
          classification5: { min: 0, max: 1 },
          competition: { min: 0, max: 1 },
          estimatedExactSearchVolume: { min: 0, max: 1 },
          excludeKeywords: ['water', 'bottle'],
          includeKeywords: ['daily', 'magazine'],
          monthlyTrend: { min: 0, max: 1 },
          opportunityScore: { min: 0, max: 1 },
          quarterlyTrend: { min: 0, max: 1 }
        }
   *  }
   */
  getKeywords(data) {
    const { filters, skipCounter, excludeTopBrands } = data
    const builtFilters = buildFilter(filters)

    const formattedData = formatOppFinderApiParams(builtFilters)

    return post(
      buildFullPath('api/keywords/get_keywords', {
        skipCounter
      }),
      {
        data: {
          ...formattedData,
          excludeTopBrands,
          search_period: 90
        },
        featureLimitKey: 'opportunityFinder'
      }
    )
  },

  getKeywordPresets() {
    const path = 'api/keywords/presets'
    return get(path)
  },

  saveKeywordPreset(data) {
    let path = `api/keywords/save_preset?${buildFilterQuery(data.filters)}`
    path += `&name=${data.presets.name}`
    path += `&country=${data.data.selectedCountry}`
    path += `&is_private=${data.presets.is_private}`
    return get(path)
  },

  loadKeywordPreset(data) {
    const path = `api/keywords/load_preset`
    return post(path, { data })
  },

  deleteKeywordPreset(data) {
    const path = `api/keywords/delete_preset`
    return post(path, { data })
  },

  getKeywordLists() {
    const path = `api/keyword_lists`
    return get(path)
  },

  getKeywordListItems(keywordListId) {
    const path = `api/keyword_lists/${keywordListId}/keyword_list_items?page=1&limit=5000`
    return get(path)
  },

  getKeywordListItemsData(keywordListItemIds) {
    const data = { keyword_ids: keywordListItemIds }
    const path = `api/keyword_engine/keywords_batch`
    return post(path, { data })
  },

  importKeywordListItems(
    keywordListId,
    country,
    keywords,
    keepExisting = true,
    keywords_with_countries
  ) {
    const data = {
      keywords,
      country,
      keep_existing: keepExisting,
      keywords_with_countries
    }
    const path = `api/keyword_lists/${keywordListId}/keyword_list_items/import`
    return post(path, { data })
  },

  createKeywordList(data) {
    return post(KEYWORD_LISTS_ENDPOINT, { data })
  },

  getProducts(data) {
    const path = 'api/products/get_products'

    return getProductsAsPost(path, data)
  },

  getProductsByAsin(country, asins) {
    const queryParams = qs.stringify({ country, asins: asins.join() })
    const path = `api/products/get_top_products?${queryParams}`

    return get(path)
  },

  getSimilarProducts(data, signal) {
    const path = `api/products/similar?${buildFilterQuery(
      data.similarProductFilters
    )}`
    return get(path, { signal })
  },

  refreshProduct(data, queryStringOptions) {
    const path = `api/products/update_product?${qs.stringify(
      queryStringOptions
    )}`
    return post(path, { data })
  },

  getDatabasePresets() {
    const path = 'api/products/presets'
    return get(path)
  },

  saveDatabasePreset(data) {
    let path = `api/products/save_preset?${buildFilterQuery(data.filters)}`
    path += `&name=${data.presets.name}`
    path += `&country=${data.data.selectedCountry}`
    path += `&is_private=${data.presets.is_private}`
    return get(path)
  },

  loadDatabasePreset(data) {
    const path = `api/products/load_preset`
    return post(path, { data })
  },

  deleteDatabasePreset(data) {
    const path = `api/products/delete_preset`
    return post(path, { data })
  },

  createTrackerCategory(data) {
    const path = 'api/tracker_categories'
    return post(path, { data })
  },

  getTrackerCategories() {
    const path = `api/tracker_categories`
    return get(path)
  },

  updateTrackerCategory(data) {
    const path = 'api/tracker_category'
    return put(path, { data })
  },

  deleteTrackerCategory(data) {
    const path = 'api/tracker_category'
    return destroy(path, { data })
  },

  sortCategory(data) {
    const path = 'api/tracker_category/sort'
    return post(path, { data })
  },

  getProductTracks(data) {
    const path = buildFullPath('api/product_tracks', data)
    return get(path)
  },

  getProductTracksAllCategories() {
    return get('api/product_tracks/all_categories', { timeout: 60000 })
  },

  batchCategorizeProductTrack(data) {
    const path = 'api/product_tracks/batch_categorize'
    return post(path, { data })
  },

  createProductTrack(data) {
    const path = 'api/product_tracks/create_from_web_app'
    return post(path, { data, featureLimitKey: 'productTracker' })
  },

  createProductTracksBulk(data) {
    const path = 'api/product_tracks/create_from_web_app_bulk'
    return post(path, { data, featureLimitKey: 'productTracker' })
  },

  deleteProductTrack(data) {
    const path = 'api/product_tracks/delete'
    return destroy(path, { data })
  },

  batchDeleteProductTrack(data) {
    const path = 'api/product_tracks/batch_delete'
    return destroy(path, { data })
  },

  loadProductTrackData(data) {
    const path = 'api/product_tracks/chart_view'
    return post(path, { data })
  },

  getTrackerNotes(data) {
    const path = `api/tracker_notes/index?product_track_id=${data.product_track_id}`
    return get(path)
  },

  createTrackerNote(data) {
    const path = `api/tracker_notes/create`
    return post(path, { data })
  },

  updateTrackerNote(data) {
    const path = `api/tracker_notes/update`
    return put(path, { data })
  },

  deleteTrackerNote(data) {
    const path = `api/tracker_notes/delete`
    return destroy(path, { data })
  },

  updateJspupWarning(data) {
    const path = 'api/onboarding/update_jspup_warning'
    return post(path, { data })
  },

  uploadAccountPhoto(data) {
    const path = 'api/account_photo'
    return post(path, { data, multipart: true })
  },

  deleteAccountPhoto(data) {
    const path = 'api/account_photo'
    return destroy(path, { data })
  },

  updateProfile(data) {
    const path = 'api/update_profile'
    return put(path, { data })
  },

  updatePassword(data) {
    const path = 'api/update_password'
    return put(path, { data })
  },

  inviteUsers(data) {
    const path = 'api/invite_users'
    return post(path, { data })
  },

  resendInvite(data) {
    const path = 'api/resend_invite'
    return post(path, { data })
  },

  revokeUser(data) {
    const path = 'api/revoke_user'
    return post(path, { data })
  },

  updateInvoiceInfo(data) {
    const path = 'api/update_invoice_info'
    return put(path, { data })
  },

  getInvoices(data) {
    const path = `api/invoices?page=${data.page}`
    return get(path, { data })
  },

  cancelMembership(data) {
    const path = 'api/cancel_membership'
    return put(path, { data })
  },

  reactivateMembership(data) {
    const path = 'api/reactivate_subscriptions'
    return put(path, { data })
  },

  confirmMembership(data) {
    const path = 'api/confirm_membership_paid'
    return put(path, { data })
  },

  deleteMembership(data) {
    const path = 'api/delete_membership'
    return destroy(path, { data })
  },

  removeCard(data) {
    const path = 'api/remove_credit_card'
    return put(path, { data })
  },

  updateCard(data) {
    const path = 'api/update_credit_card'
    return put(path, { data })
  },

  sendMetrics(data) {
    const path = buildFullPath('api/make_metric', data)
    return get(path)
  },

  sendAnonymousMetrics(data) {
    const path = 'api/make_anonymous_metric'
    return post(path, { data })
  },

  downloadInvoice(data) {
    const path = `api/download_invoice`
    return post(path, { data, responseType: 'blob' })
  },

  downloadMultipleInvoices(data) {
    const path = `api/download_multiple_invoices`
    return post(path, { data, responseType: 'blob' })
  },

  emailInvoices(data) {
    const path = 'api/email_invoices'
    return post(path, { data })
  },

  adminGetProduct(data) {
    const path = `api/admin/products?country=${data.country}&asin=${data.asin}`
    return get(path)
  },

  adminGetKeyword(data) {
    const path = `api/admin/keywords?country=${data.country}&keyword=${data.keyword}`
    return get(path)
  },

  fetchProductDetails(data) {
    let path = `api/product_details/${data.asin}`
    path += data.country ? `?country=${data.country}` : ''
    return get(path)
  },

  createUserLesson(data) {
    const path = `api/user_lessons`
    return post(path, { data })
  },

  updateUserLesson(data) {
    const path = `api/user_lessons/${data.id}`
    return put(path, { data })
  },

  createUserCourse(data) {
    const path = `api/user_courses`
    return post(path, { data })
  },

  ...academy,
  ...accounts,
  ...admin,
  ...adminUsers,
  ...alerts,
  ...emailUnsubscribeLink,
  ...historicalData,
  ...marketInsights,
  ...ppc,
  ...product,
  ...productAlerts,
  ...productCosts,
  ...salesAnalytics,
  ...seats,
  ...sessions,
  ...settings,
  ...subscriptionItems,
  ...users
}
